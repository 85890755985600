import Cookies from 'js-cookie'

if (!window.Cookies){
  window.Cookies = Cookies
}

import Base64 from 'js-base64'
if (!window.Base64) {
  window.Base64 = Base64;
}

